import hiMessages from '../locales/hi-Hindi.json';

const Hindi = {
  messages: {
    ...hiMessages,
  },
  antd: null,
  locale: 'hi-Hindi',
};
export default Hindi;
